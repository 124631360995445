import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ data, location }) => (
  <Layout location={location}>
    <div className="page-wrapper-inner">
      <Seo
        title="Contact"
        slug={location.pathname}
      />
      {/* Main */}
      <section className="wrapper style1">
        <div className="container">
          <div id="content">

            {/* Content */}
            <article>
              <header>
                <h2>Contact Information</h2>
              </header>

              <p>
                <strong>Mailing address:</strong>
                <br />
                Rosewood Family Advisors, LLP
                <br />
                PO Box 61239
                <br />
                Palo Alto, CA  94306
              </p>

              <p>
                <strong>Physical address:</strong>
                <br />
                2475 Hanover Street
                <br />
                Suite 100
                <br />
                Palo Alto, CA 94304
              </p>

              <p>Email: <a href="mailto:info@rfallp.com">info@rfallp.com</a></p>

            </article>

          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default InteriorPage;
